






























































































/* eslint-disable no-underscore-dangle, no-lonely-if */
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Component } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import InputText from '@/components/InputText.vue';
import { required } from 'vuelidate/lib/validators';
import AlertComponent from '@/components/AlertComponent.vue';
import { validationMixin } from 'vuelidate';
import { namespace } from 'vuex-class';
import AlertType from '@/utils/enums/AlertType';
import AuthStep from '@/utils/enums/AuthStep';
import CommunityUser from '@/models/graphql/CommunityUser';
import AuthPageGuard from '@/guards/AuthPageGuard';

const authenticationStore = namespace('AuthenticationStore');
const communityUserStore = namespace('CommunityUserStore');

@Component({
  components: {
    ButtonComponent,
    InputText,
    AlertComponent,
  },
  data(): object {
    return {
      firstNameError: false,
      lastNameError: false,
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required,
      },
      firstName: {
        required,
      },
      lastName: {
        required,
      },
    },
  },
  beforeRouteEnter: AuthPageGuard.beforeEnter,
})
export default class SignInEnterInformation extends VueBaseWidget {
  @authenticationStore.Getter
  private readonly isAuthenticated!: boolean;

  @authenticationStore.State
  private readonly authStep!: AuthStep | null;

  @authenticationStore.State
  private readonly authEmail!: string | null;

  @authenticationStore.Action
  private logout!: () => Promise<void>;

  @authenticationStore.Action
  private login!: () => Promise<CommunityUser>;

  @authenticationStore.Action
  private activateAccount!: () => Promise<boolean>;

  @authenticationStore.Action
  private createAccount!: (model: CommunityUser) => Promise<CommunityUser | undefined>;

  @communityUserStore.Action
  private updateUserProfile!: (payload: Partial<CommunityUser>) => Promise<CommunityUser | undefined>;

  private isLoading = false;

  private firstSubmit = false;

  private alertType = AlertType;

  private informationError = false;

  private serviceError = false;

  private form: {
    email: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
  } = {
    email: '',
    firstName: '',
    lastName: '',
  };

  created(): void {
    if (this.isAuthenticated
      && !this.authUser._needsActivation
      && !this.authUser._needsNameCreated
      && this.authUser.firstName !== ''
      && this.authUser.lastName !== ''
    ) {
      this.$router.push({ path: '/' });
    }

    if (this.isAuthenticated
      && (this.authUser._needsActivation === null || this.authUser._needsActivation)
      && !this.authUser._needsNameCreated
      && !this.authUser._needsPasswordCreated
      && !this.authUser._needsEmailDisambiguated
      && this.authUser.firstName !== ''
      && this.authUser.lastName !== ''
      && this.authUser.lastName !== 'Owner'
    ) {
      this.activateAccount()
        .then((res) => {
          if (res) {
            this.$router.push({ path: '/' });
          } else {
            this.informationError = true;
          }
        });
    }
    if (!this.authEmail && !this.isAuthenticated) {
      this.$router.push({ name: 'sign-in' });
    }
    if (this.authUser && this.authUser._needsPasswordCreated) {
      this.$router.push({ name: 'create-password' });
    }
    if (this.authUser && this.authUser._needsEmailDisambiguated) {
      this.$router.push({ name: 'email-disambiguated' });
    }
    if (this.isAuthenticated) {
      this.form.email = this.authUser.email as string;
      this.form.firstName = this.authUser.firstName as string;
      this.form.lastName = this.authUser.lastName as string;
    } else {
      this.form.email = this.authEmail as string;
    }
  }

  private onBlur(field: string): void {
    this.$data.submitError = false;
    if (this.$v) {
      switch (field) {
        case 'firstName':
          this.$data.firstNameError = this.$v.form.firstName ? this.$v.form.firstName.$invalid : false;
          break;
        case 'lastName':
          this.$data.lastNameError = this.$v.form.lastName ? this.$v.form.lastName.$invalid : false;
          break;
        default:
          break;
      }
    }
  }

  private onContinueAsGuest(): void {
    this.logout()
      .then(() => {
        this.$router.replace({ path: '/' });
      });
  }

  private onSubmit(): void {
    if (!this.isLoading) {
      this.isLoading = true;
      this.firstSubmit = true;
      this.onBlur('firstName');
      this.onBlur('lastName');
      if (this.isAuthenticated
        && this.authUser
        && this.authUser.uid
        && !this.$data.firstNameError
        && !this.$data.lastNameError
      ) {
        this.updateUserProfile({
          firstName: this.form.firstName,
          lastName: this.form.lastName,
        })
          .then((response) => {
            if (response && response.uid === this.authUser.uid) {
              this.activateAccount()
                .then((res) => {
                  if (res) {
                    this.$router.push({ path: '/' });
                  } else {
                    this.informationError = true;
                  }
                })
                .catch(() => {
                  this.serviceError = true;
                });
            } else {
              this.informationError = true;
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.serviceError = true;
            this.isLoading = false;
          });
      } else {
        this.isLoading = false;
      }
    }
  }
}
